body.login {
  padding: 0;
}

.login--container {
  display: flex;
  width: 100vw;
  height: 100vh;
  @media screen and (max-device-width: 428px) {
    flex-direction: column;
  }
}

.login--container-item {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  margin: 0;
  box-sizing: border-box;
  display: flex;

  @media screen and (max-device-width: 428px) {
    max-width: 100%;
  }
}

.login--container-form-container {
  @extend .login--container-item;
  padding: 64px;
  background-color: #fff;
}

.login--container-image {
  @extend .login--container-item;
  background-size: cover;
  background-image: url(./background.jpg);
}

.login--container--form--button-container {
  display: flex;
  justify-content: flex-end;
}
