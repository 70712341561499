.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading__child {
  width: 20px;
  height: 20px;
  background-color: rgb(227, 6, 19);
  border-radius: 100%;
  display: inline-block;
  animation: loading-bounce 1.4s ease-in-out 0s infinite both;
}

.loading__child--bounce1 {
  animation-delay: -0.32s;
}

.loading__child--bounce2 {
  animation-delay: -0.16s;
}

@-webkit-keyframes loading-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes loading-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
